<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status','goods_logo']"
            ref="list"
        >
            <template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加商品</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.text=='1'">上架</span>
                <span v-else-if="data.text=='0'">下架</span>
            </template>

            <template slot="goods_logo" slot-scope="data">
                <img v-if="data.text" style="width:50px; height:50px" :src="data.text" alt />
                <img v-else style="width:50px; height:50px" src="@/assets/img/null_img.png" alt />
            </template>

            <template slot="goods_put" slot-scope="data">
                <span v-if="data.text=='1'">是</span>
                <span v-else-if="data.text=='0'">否</span>
            </template>

            <template slot="operation" slot-scope="data">
                <span>
                    <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                </span>
                <a-divider type="vertical" />
                <span>
                    <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
                        <a>删除</a>
                    </a-popconfirm>
                </span>
            </template>
        </TableList>
        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">
			
		</EditPop>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { getEmptyGoodsList,changeGoods,goodsDelete} from "@/api/push";

let columns = [
    {
        title: "商品图片",
        dataIndex: "goods_logo",
        scopedSlots: {
            customRender: "goods_logo"
        }
    },
    {
        title: "商品名称",
        dataIndex: "goods_title",
    },
    {
        title: "drp_id",
        dataIndex: "drp_id",
        // scopedSlots: {
        //     customRender: "goods_num"
        // }
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "emptyIndex",
    components: {
        EditPop,
        Form,
        TableList,
    },
    data() {
        return {
            get_table_list: getEmptyGoodsList,
            form: this.$form.createForm(this, {
                name: "advanced_search"
            }),
            columns,
            visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "title",
                        title: "商品",
                        placeholder: '商品名称',
                        options: {

                        }
                    },

                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {
                title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'goods_title',
                        title: '商品名称',
                        options: {
							rules: [
							    {
							        required: true,
							        message: "请输入商品名称"
							    }
							]
                        },
					},
					{
					    type: 'text',
					    name: 'goods_desc',
					    title: '货物描述',
					    options: {},
					},
					{
					    type: 'text',
					    name: 'drp_id',
					    title: 'drp_id',
					    options: {},
					},
                    {
                        type: 'radio',
                        name: 'status',
                        title: '状态',
                        list: [
                            {
                                key: 1,
                                value: "上架"
                            },
                            {
                                key: 0,
                                value: "下架"
                            }
                        ],
                        options: {
                        	
                        },
                    },
                    {
                        type: 'text',
                        name: 'goods_spec',
                        title: '规格',
                        options: {
                        	rules: [
                        	    {
                        	        message: "请输入商品规格"
                        	    }
                        	]
                        },
                    },
                    {
                        type: 'upload',
                        name: 'goods_logo',
                        title: '商品图片',
                        options: {},
                    },
                    {
                        type: 'textarea',
                        name: 'remarks',
                        title: '备注',
                        options: {},
                    },
                ]
            },
        };
    },
    async created() {// 获取分类列表（树结构）
		this.get_department(); // 获取部门树（编辑/添加时的所属区域）
    },
	activated() {
			this.$refs.list.get_list();
	},
    methods: {
		// 获取部门树形数据
		get_department(){
			this.$method.get_department().then(res=>{
				this.$method.set_form_list(this.form_data.list,'department_ids','treeData',res);
				this.$method.set_form_list(this.form_data_seo.list,'department_ids','treeData',res);
			})
		},
		// 获取商品分类列表，树形数据结构
		get_cate_list(){
			goodsCate().then(res=>{
				this.$refs.list.get_list();
			})
		},
		// 深度优先遍历
		deepFirstSearch(node,nodeList = [],fun,first_load = false) {
		    if (node) {
				nodeList.push(node);
		        let children = node.children || [];
		        for (let i = 0; i < children.length; i++){
					//每次递归的时候将 需要遍历的节点 和 节点所存储的数组传下去
					fun(node,children[i]);
					this.deepFirstSearch(children[i],nodeList,fun);    
				}
		    }    
		    return nodeList; // 返回遍历过的节点（深度优先遍历顺序）
		},
        add() {
            this.form_data.title = "添加"
            this.visible = true;
            this.form_data.list.forEach(item => {
                if (item.type == 'upload') {
                    item.options.initialValue = [];
                }else if(item.type == 'tree-select'){
					item.options.initialValue = null; // tree-select组件需要在初始将值设置为null，否则tree-select的输入框会存在内容异常或没有placeholder问题
				}else if(item.type == 'cascader'){
					item.options.initialValue = [];
				}else if(item.type !== 'slot'){
					item.options.initialValue = "";
				}
				
				if(item.name == 'goods_put' || item.name == 'status'){
					item.options.initialValue = 1;
				}
            })
        },
        edit(record) {
            this.form_data.title = "编辑"
            let data = record
            this.form_data.list.forEach(item => {
                if (item.type == 'upload') {
                    let val = {};
                    val[item.name] = {
                        fileList: [{
                            url: data[item.name]
                        }]
                    }
                    item.options.initialValue = data[item.name] ? [{
                        uid: data[item.name],
                        name: data[item.name],
                        status: 'done',
                        url: data[item.name]
                    }] : [];

                } else if(item.type != 'slot') {
                    item.options.initialValue = data[item.name];
                }
            })
            this.visible = true;
        },
        del(record) {
            goodsDelete({
                data: {
                    id: record.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
			
			if(data.department_ids && data.department_ids.length){
				data.department_ids = data.department_ids.toString();
			}

            changeGoods({
                data: {
                    ...data
                },
				info:true,
            }).then(res => {
                this.visible = false;
                this.$refs.list.get_list();
            })

        },

    }
};
</script>

<style lang="less">
@import url("../../../assets/less/app.less");
.max_price_tips{
	margin-left: 99px;
	margin-top: -30px;
	font-size: 13px;
}
.float_left{
	margin-right: 8px;
}
.max_price_form{
	.item{
		display: flex;
		.right{
			flex:1;
			.input_number{
				width: 100%;
			}
		}
	}
}
</style>